















import {Component, Vue} from "vue-property-decorator";
import CariHareketList from "@/components/comps/lists/CariHareketList.vue";
@Component({
    components: {
        CariHareketList
    }
})
export default class CariKart extends Vue {

}
